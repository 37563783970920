<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="widjet m-2">
          <div class="widjethdr dispflex">
            <div style="width: auto">
              <span class="iconsect blubg" style="padding-left: 12px"
                ><i class="fas fa-receipt"></i
              ></span>
              <h1>Assignments</h1>
            </div>
          </div>
          <div class=" tbllist" v-if="assignmentView == 'List'">
            <div
              class="crtfeeplancont"
              style="padding: 0 13px"
              v-if="assignmentList.length > 0"
            >
              <div
                class="widjethdr dispflex"
                style="align-items: baseline; padding: 10px 0"
              ></div>
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Assignment Name</div>
                  <div class="cell">Assignment Type</div>
                  <div class="cell">publish Date</div>
                  <div class="cell">Due Date</div>
                  <div class="cell">Submission Type</div>
                  <div class="cell">Status</div>
                  <div class="cell">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(item, index) in assignmentList"
                  :key="index"
                >
                  <div class="cell">
                    {{ item.assignment ? item.assignment.name : "" }}
                  </div>
                  <div class="cell">
                    {{ item.assignment ? item.assignment.type : "" }}
                  </div>

                  <div class="cell">
                    {{ item.submitDate | dateFormat }}
                  </div>
                  <div class="cell">{{ item.dueDate | dateFormat }}</div>
                  <div class="cell">
                    {{ item.assignment ? item.assignment.submissionType : "" }}
                  </div>
                  <div class="cell">{{ item.status }}</div>

                  <div class="cell">
                    <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                      <b-dropdown-item @click="viewMaterialPopup(item)">Upload Document</b-dropdown-item>
                    </b-dropdown>
                    <button class="btn round orange tooltipt" @click="editAssignment(item)">
                      <i class="far fa-eye"></i
                      ><span class="tooltiptext">View</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="crtfeeplancont"
              style="padding: 0 13px"
              v-if="assignmentList.length == 0"
            >
              <div style="margin-top: 10%; text-align: center">
                No assignments added
              </div>
            </div>
          </div>
          <div class="widjetcontent" v-else>
            <div class="crtfeeplancont" style="padding: 0 13px">
              <FullCalendar :options="calendarOptions" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modalOne"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
      ><h2>Assignment</h2>
      <br />
      <div>
        <form
          data-vv-scope="assignmentValidate"
          id="addteacher"
          accept-charset="utf-8"
        >
          <div class="row">
            <div class="col-lg-12">
              <div class="widjet">
                <div class="widjethdr dispflex">
                  <div class="form-row" style="width: 100%">
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input v-if="asgntDetails.assignment"
                          type="text"
                          name="asgntDetailsname"
                          class="form-control form-input"
                          id="asgntDetailsname"
                          disabled
                          v-validate="'required'"
                          v-model="asgntDetails.assignment.name"
                        />
                       
                        <label  for="ctid"
                          >Assignment Name <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select v-if="asgntDetails.assignment"
                          name="assType"
                          class="form-control form-input"
                          id="assType"
                          disabled
                          v-validate="'required'"
                          v-model="asgntDetails.assignment.type"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in typeList"
                            :key="index"
                            :value="item.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>
                       
                        <label for="typeId" 
                          >Type <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          name="asssubject"
                          class="form-control form-input"
                          id="asssubject"
                          v-validate="'required'"
                          v-model="asgntDetails.subject"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in teacherAllowSubjects"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.subjectName }}
                          </option>
                        </select>
                        <label
                          v-if="errors.first('assignmentValidate.asssubject')"
                          class="vi-error"
                          for="emailid"
                          style="top: -1em"
                          >Subject is required</label
                        >
                        <label for="typeId" v-else
                          >Subject <span class="required">*</span></label
                        >
                      </span>
                    </div> -->
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input v-if="asgntDetails.assignment"
                          type="date"
                          disabled
                          name="publishDate"
                          class="form-control form-input"
                          id="publishDateId"
                          v-validate="'required'"
                          v-model="asgntDetails.assignment.publishedDate"
                        />
                       
                        <label for="publishDateId">Publish Date</label>
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input v-if="asgntDetails.assignment"
                          type="date"
                          disabled
                          name="aadueDate"
                          class="form-control form-input"
                          id="aadueDate"
                          v-validate="'required'"
                          v-model="asgntDetails.assignment.dueDate"
                        />
                       
                        <label  for="publishDateId">Due Date</label>
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select v-if="asgntDetails.assignment"
                        disabled 
                          name="assSubType"
                          v-validate="'required'"
                          class="form-control form-input"
                          id="typeId"
                          v-model="asgntDetails.assignment.submissionType"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in submissionTypeList"
                            :key="index"
                            :value="item.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>
                       
                        <label for="typeId" 
                          >Submission Type
                          <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <!-- <span > {{ asgntDetails.mark }}/{{ assignmentTotalMarks }}</span> -->
                        
                        <input 
                          type="text"
                          disabled
                          name="totalMarks"
                          class="form-control form-input"
                          id="totalMarksId"
                          :value="getTotalMarks(asgntDetails.mark,assignmentTotalMarks)"
                         
                        />
                        <label for="totalMarksId">Marks </label>
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input 
                          type="text"
                          disabled
                          name="totalMarks"
                          class="form-control form-input"
                          id="totalMarksId"
                          v-model="asgntDetails.status"
                        />
                        <label for="totalMarksId">Status </label>
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input 
                          type="text"
                          disabled
                          name="totalMarks"
                          class="form-control form-input"
                          id="totalMarksId"
                          v-model="asgntDetails.recieveNote"
                        />
                        <label for="totalMarksId">FeedBack </label>
                      </span>
                    </div>
                    
                    <!-- <div class="form-group input-group col-md-8 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <textarea v-if="asgntDetails.assignment"
                          class="form-control form-input"
                          style="min-height: 90px; resize: none"
                          id="descriptionId"
                          disabled
                          v-model="asgntDetails.assignment.description"
                        ></textarea>
                        <label for="descriptionId">Description</label>
                      </span>
                    </div> -->
                   

                  </div>
                </div>
                <div class="form-row" v-if="asgntDetails.assignment && asgntDetails.assignment.attachments.length > 0">
                    <div v-if="asgntDetails.assignment && asgntDetails.assignment.attachments.length > 0"
                      class="form-group input-group col-md-2 mt-3 pr-md-3"
                     
                    >
                      Attachment
                    </div>
                    <div
                      class="form-group input-group col-md-10 mt-3 pr-md-3"
                      
                    >
                  
                           <span v-if="asgntDetails.assignment && asgntDetails.assignment.attachments.length > 0">{{  asgntDetails.assignment && asgntDetails.assignment.attachments[0].name  }}</span>
                          
                           <button v-if="asgntDetails.assignment && asgntDetails.assignment.attachments.length > 0" class="btn btn-doc btn-sm" style="background-color: darkseagreen;margin-left: 10%;"  @click.prevent="preViewDocument(asgntDetails.assignment && asgntDetails.assignment.attachments[0].url.Location)">
                              View
                            </button>
                           
                    </div>
                  </div>
                <div class="widjetfooter text-center dkicon">
                 
                  <button
                    type="button"
                    @click="closeModalOne"
                    class="btn btncl clsmdl"
                  >
                       <span>Back</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal
      id="materialPopup"
      style="width: 100%"
     
    >
    <button type="button" class="close"  @click.prevent="closePoup">×</button>

      <div class="contact-form">
        <h2>Documents</h2>
        <br />
        <div>
          <form
            id="crtfeeplans"
            accept-charset="utf-8"
          >
          <div class="row">
         
          <div class="col-lg-12">
              
               <div class="form-row" >
                           <input
                            type="file"
                            id="importExportID"
                            ref="attachDoc"
                            name="attachDoc"
                            accept="application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpg, image/png"
                            @change="updateFile('attachDoc')"
                           />
              </div>
              <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in uploadDocment.documents" :key="indexDoc">
               <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.name"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Name</label>
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input 
                    v-model="doc.date"
                    disabled
                    class="form-control form-input"
                    id="fpstartdateid"
                  />
                  <label  for="fpstartdateid">File Upload Date</label>
                </span>
              </div>
               <div class="form-group input-group col-md-2 mt-3 ">
                <button class="btn btn-doc btn-sm" style="background-color: darkseagreen;"  @click.prevent="preViewDocument(doc.url.Location)">
                              View
                            </button>
                            <button class="btn btn-doc btn-sm" style="margin-left: 7px;background-color: indianred;" @click.prevent="removeDocument(indexDoc,doc.url.key)">
                              Delete
                            </button>
                            <!-- //key -->
                 <!-- <button class="btn round red tooltipt"   style="height:1.8rem"><i class="far fa-trash-alt"></i><span class="tooltiptext">Delete</span></button> -->
               
              </div>
              </div>
          </div>
          </div>
          </form>

          <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <!-- <div class="dk_icon"></div> -->
            <div class="text-center dk_iconsml">
            
             
              <button
                @click.prevent="closePoup"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modalTwo"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div class="form-row" style="width: 100%">
                <div class="form-group input-group col-md-12 mt-3 pr-md-3">
                  <div style="width: 100%">
                    <div class=" tbllist">
                      <div class="crtfeeplancont" style="padding: 0 13px">
                        <div
                          class="widjethdr dispflex"
                          style="align-items: baseline; padding: 10px 0"
                        ></div>
                        <div class="restable">
                          <div class="resrow resheader">
                            <div class="cell"></div>
                            <div class="cell">Class</div>
                            <div class="cell">Section</div>
                            <div class="cell">Student Name</div>
                            <div class="cell">Status</div>
                            <div class="cell">Feedback</div>
                            <div class="cell">Mark</div>
                          </div>
                          <div
                            class="resrow"
                            v-for="(item, index) in evaluationList"
                            :key="index"
                          >
                            <div class="cell"></div>
                            <div class="cell">
                              {{ getClassName(item.class) }}
                            </div>
                            <div class="cell">
                              {{ getSectionName(item.classRoom) }}
                            </div>
                            <div class="cell">{{ item.studentName }}</div>
                            <div class="cell">
                              <span class="has-float-label">
                                <select
                                  name="Type"
                                  v-model="item.status"
                                  class="form-control form-input"
                                  id="typeId"
                                >
                                  <option :value="null">--Select--</option>
                                  <option
                                    v-for="(item, index) in statusList"
                                    :key="index"
                                    :value="item.value"
                                  >
                                    {{ item.label }}
                                  </option>
                                </select>

                                <label for="typeId"
                                  >Status <span class="required">*</span></label
                                >
                              </span>
                            </div>
                            <div class="cell">
                              <span class="has-float-label">
                                <input
                                  type="text"
                                  v-model="item.recieveNote"
                                  name="feedback"
                                  class="form-control form-input"
                                  id="feedbackId"
                                />
                                <label for="feedbackId">Feedback</label>
                              </span>
                            </div>
                            <div class="cell">
                              <span class="has-float-label">
                                <input
                                  v-model="item.mark"
                                  type="number"
                                  name="mark"
                                  class="form-control form-input"
                                  id="markId"
                                />
                                <label for="markId">Mark</label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="widjetfooter text-right dkicon">
              <button
                @click="updateStudentAssignment"
                type="button"
                class="btn btnsml"
                id="svbtn"
              >
                  <span>Save</span>
              </button>
              <button
                type="button"
                @click="closeModalTwo"
                class="btn btncl clsmdl"
              >
                   <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="resultPopUp"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div class="form-row" style="width: 100%">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <input
                    type="text"
                    style="margin-left: 4%"
                    placeholder="Search Students"
                  />
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <button
                    type="button"
                    style="width: 24%"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    Contribution
                  </button>
                  <button
                    type="button"
                    style="width: 24%"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    Calculate
                  </button>
                  <button
                    type="button"
                    style="width: 24%"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>

            <div class="tbllist mt-1">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">S.No</div>
                  <div class="cell">Student Name</div>
                  <div class="cell">Grade</div>
                  <div class="cell">%</div>
                  <div class="cell">Ranks</div>
                  <div class="cell">Marks</div>
                  <div class="cell">Absent/Present</div>
                  <div class="cell">Comments</div>
                </div>

                <div class="resrow">
                  <div class="cell">1</div>
                  <div class="cell">Saran</div>
                  <div class="cell">A+</div>
                  <div class="cell">100%</div>
                  <div class="cell">1</div>
                  <div class="cell">9119</div>
                  <div class="cell">Present</div>
                  <div class="cell">
                    <textarea
                      name="comment"
                      class="form-control form-input"
                      style="min-height: 40px; resize: none"
                      id="tocmts"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="widjetfooter text-right dkicon">
              <button
                type="button"
                @click="closeResultPopUp"
                class="btn btncl clsmdl"
              >
                   <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import secureUI from "../../../utils/secureUI";
import ViService from "@/services/ViService";

export default {
  name: "AppTeacherAcademicManagementAssignment",
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
    terms: {
      type: Array,
    },
    accountId: {
      type: String,
    },
    academicYearId: {
      type: String,
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        weekends: false,
        events: [],
      },
      uploadDocment: {},
      assignmentTotalMarks: '',
      evaluationList: [],
      assignmentView: "List",
      showSetupTimeTable: false,
      periodList: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      createPeriodList: [
        {
          day: "Mon",
        },
        {
          day: "Tue",
        },
        {
          day: "Wed",
        },
        {
          day: "Thu",
        },
        {
          day: "Fri",
        },
        {
          day: "Sat",
        },
      ],
      submittedCount: 0,
      completedCount: 0,
      dueCount: 0,
      assignmentDetails: {
        view: "",
        from: "",
        to: "",
        skip: 0,
        limit: 100,
        class: this.classId,
        classroom: this.classRoom,
        needCount: true,
      },
      assignmentList: [],
      assignedStudentList: [],
      classList: [],
      allSectionList: [],
      typeList: [
        {
          label: "Homework",
          value: "Homework",
        },
        {
          label: "Quiz",
          value: "Quiz",
        },
        {
          label: "Test",
          value: "Test",
        },
        {
          label: "Online Class",
          value: "Online Class",
        },
        {
          label: "Offline Class",
          value: "Offline Class",
        },
        {
          label: "Remedial",
          value: "Remedial",
        },
        {
          label: "Worksheet",
          value: "Worksheet",
        },
        {
          label: "Holiday Homework",
          value: "Holiday Homework",
        },
      ],
      statusList: [
        {
          label: "Submitted",
          value: "Submitted",
        },
        {
          label: "Completed",
          value: "Completed",
        },
        {
          label: "Due",
          value: "Due",
        },
      ],
      subjectList: [],
      teacherAllowSubjects: [],
      submissionTypeList: [
        {
          label: "Online Submission",
          value: "Online Submission",
        },
        {
          label: "Hard Copy",
          value: "Hard Copy",
        },
        {
          label: "URL Link",
          value: "URL Link",
        },
        {
          label: "Google Classroom",
          value: "Google Classroom",
        },
        {
          label: "Hindi",
          value: "Hindi",
        },
      ],
      asgntDetails: {},
      sectionList: [],
      studentList: [],
      selectedClass: null,
      selectedSection: null,
      showClassSelection: false,
      showSectionSelection: false,
      showFullSection: false,
      showClassTable: false,
      showSectionTable: false,
      showStudentTable: false,
    };
  },
  created() {
    this.getAssignmentList();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },

  methods: {
    getTotalMarks(mark,totalmarks){
      return mark + '/' + totalmarks
    },
    getStatusCount(data, type) {
      if (data.length > 0) {
        if (type == "Submitted") {
          const submittedDetails = data.filter(
            (x) =>
              x.status == "Submitted" &&
              x.classRoom.toString() == this.classRoom.toString()
          );
          return submittedDetails.length;
        }
        if (type == "Completed") {
          const completedDetails = data.filter(
            (x) =>
              x.status == "Completed" &&
              x.classRoom.toString() == this.classRoom.toString()
          );
          return completedDetails.length;
        }
        if (type == "Due") {
          return 0;
        }
      }
    },
    openAssignTeacherPopUp() {
      this.$bvModal.show("assignTeacherPopUp");
    },
    closeAssignTeacherPopUp() {
      this.$bvModal.hide("assignTeacherPopUp");
    },
    openResultPopUp() {
      this.$bvModal.show("resultPopUp");
    },
    closeResultPopUp() {
      this.$bvModal.hide("resultPopUp");
    },

    openClassPerformance() {
      this.$bvModal.show("classPerformancePopUp");
    },
    closeClassPerformancePopUp() {
      this.$bvModal.hide("classPerformancePopUp");
    },
    openCurriculumPopUp() {
      this.$bvModal.show("curriculumPopUp");
    },
    closeCurriculumPopUp() {
      this.$bvModal.hide("curriculumPopUp");
    },
    openCreateTimeTablePopUp() {
      this.$bvModal.show("createTimeTablePopUp");
    },
    closeCreateTimeTablePopUp() {
      this.$bvModal.hide("createTimeTablePopUp");
    },
    enableSetupTimeTable() {
      this.showSetupTimeTable = true;
    },
    closeSetupTimeTable() {
      this.showSetupTimeTable = false;
    },
    toggleCalendarView() {
      this.calendarOptions.events = this.assignmentList.map((val) => {
        return {
          title: val.name,
          start: new Date(val.dueDate),
          ...val,
        };
      });

      this.assignmentView = "Calender";
    },
    async confirmDelete(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viDelete(
          `/academic/assignmentDelete/${id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.getAssignmentList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async updateFile(docType) {
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0]
        
        if(docFileDetails){

          const fd = new FormData();

          fd.append(
            "doc",
            docFileDetails,
            docFileDetails.name,
            docFileDetails.actType
          );

          const response = await ViService.viPutOnly(
            `/document/uploadFile/teacherMaterial`,
            fd,
            userData.token
          );
          
          if(response.isSuccess){
            this.uploadDocment.documents.push({
              name: docFileDetails.name,
              url: response.value.url,
              date: new Date()
            })
           this.updateStudentAssignments()

          }else{
            console.log("response",response)
          }

        }
        
        document.getElementById("importExportID").value=null; 

      }

    },
    async updateStudentAssignments() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let obj = {
           _id: this.uploadDocment._id,
           documents: this.uploadDocment.documents,
           status: 'Submitted'
        }

        const currentDate = new Date();

        // }
        if(new Date(this.uploadDocment.dueDate) < currentDate ){
          
          obj.status = 'Late Submission'
        }

        const response = await ViService.viXPost(
          "/academic/student/document/assignmentsUpdate",
          obj,
          userData.token
        );
        if (response.isSuccess) {
         // this.closeModalTwo();
          this.getAssignmentList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getSubjectList() {
      this.teacherAllowSubjects = [];

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/class/getSubjectList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.subjectList = secureUI.secureGet(response.data);
          if (this.subjectList.length > 0) {
            if (this.assignedSubjects.length > 0) {
              const filteredItems = this.subjectList.filter((item) =>
                this.assignedSubjects.includes(item._id.toString())
              );

              this.teacherAllowSubjects = filteredItems;
            } else {
              this.teacherAllowSubjects = this.subjectList;
            }
          }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    getClassName(classId) {
      var className = this.classList.filter((x) => x._id == classId)[0]
        .className;
      return className;
    },

    getSectionName(sectionId) {
      var sectionDetails = this.allSectionList.find((x) => x._id == sectionId);
      return sectionDetails.name ? sectionDetails.name : "";
    },

    viewMaterialPopup(data) {
     // let details = this.curriculumInfo[index];
      
      this.uploadDocment = data;
      this.uploadDocment.documents = this.uploadDocment.documents || []
      this.$bvModal.show("materialPopup");
    },
    closePoup() {
      this.getAssignmentList();
      this.$bvModal.hide("materialPopup");
    },

    async getAssignmentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let requestBody = {
          class: this.classId,
          classroom: this.classRoom,
          // term: id,
          academicYear: this.academicYearId,
          accountId: this.accountId,
          view: "list",
          studentId: this.$route.params.id,
        };

        const response = await ViService.viXPost(
          "/academic/getAssignmentsStudentView",
          requestBody,
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);

          this.assignmentList = result;

          //this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    async saveAssignment() {
      let isFormValid = false;

      await this.$validator.validateAll("assignmentValidate").then((result) => {
        isFormValid = result;
      });
      if (isFormValid) {
        if (this.asgntDetails.assignType == "class") {
          let selectedClassList = [];
          this.classList.forEach((x) => {
            if (x.isSelected) {
              selectedClassList.push(x._id);
            }
          });
          this.asgntDetails.classes = selectedClassList;
        } else if (this.asgntDetails.assignType == "classRoom") {
          let selectedSectionList = [];
          this.sectionList.forEach((x) => {
            if (x.isSelected) {
              selectedSectionList.push(x._id);
            }
          });
          this.asgntDetails.classrooms = selectedSectionList;
          this.asgntDetails["selectedClass"] = this.selectedClass;
        } else if (this.asgntDetails.assignType == "student") {
          let selectedStudentList = [];
          this.studentList.forEach((x) => {
            if (x.isSelected) {
              selectedStudentList.push(x._id);
            }
          });
          this.asgntDetails.students = selectedStudentList;
        }
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let allowToCreate = true;

          if (this.asgntDetails.assignType == "class") {
            if (this.asgntDetails.classes.length == 0) {
              allowToCreate = false;
            }
          }
          if (this.asgntDetails.assignType == "classRoom") {
            if (this.asgntDetails.classrooms.length == 0) {
              allowToCreate = false;
            }
          }
          if (this.asgntDetails.assignType == "student") {
            if (this.asgntDetails.students.length == 0) {
              allowToCreate = false;
            }
          }
          if (allowToCreate) {
            // this.asgntDetails
            const response = await ViService.viXPost(
              "/academic/assignment/save",
              this.asgntDetails,
              userData.token
            );
            if (response.isSuccess) {
              this.$bvModal.hide("modalOne");
              this.closeModalOne();
              this.getAssignmentList();
              this.$toasted.success(response.message);
            } else {
              this.$toasted.error(response.message);
              if (
                response.message == "Your session has expired, please login"
              ) {
                localStorage.removeItem("user");
                this.$router.push("/login");
              }
            }
          } else {
            if (this.asgntDetails.assignType == "class") {
              this.$toasted.error("Please Select Minimum One Class");
            }
            if (this.asgntDetails.assignType == "classRoom") {
              this.$toasted.error("Please Select Minimum One Classroom");
            }
            if (this.asgntDetails.assignType == "student") {
              this.$toasted.error("Please Select Minimum One Student");
            }
          }
        }
      } else {
        this.$toasted.error("Please fill all the required details");
      }
    },
    async updateStudentAssignment() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPost(
          "/academic/student/assignmentsUpdate",
          this.evaluationList,
          userData.token
        );
        if (response.isSuccess) {
          this.closeModalTwo();
          this.getAssignmentList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    async getStudent(assigneeType) {
      this.selectedClass = null;
      this.selectedSection = null;
      this.classList.forEach((x) => {
        x["isSelected"] = false;
      });

      // this.classList = resultData.data;

      if (assigneeType == "class") {
        this.showClassSelection = false;
        this.showSectionSelection = false;
        this.showClassTable = true;
        this.showSectionTable = false;
        this.showStudentTable = false;
        (this.sectionList = []), (this.studentList = []);
      } else if (assigneeType == "classRoom") {
        this.showClassSelection = true;
        this.showSectionSelection = false;
        this.showClassTable = false;
        this.showSectionTable = true;
        this.showStudentTable = false;
        (this.sectionList = []), (this.studentList = []);
      } else {
        this.showClassSelection = true;
        this.showSectionSelection = true;
        this.showClassTable = false;
        this.showSectionTable = false;
        this.showStudentTable = true;
        this.sectionList = [];
        this.studentList = [];
        this.sectionList = this.classList.find(
          (x) => x._id == this.classId
        ).sections;

        this.sectionList.forEach((y) => {
          y["isSelected"] = false;
        });
        this.getStudentList();
      }
      this.asgntDetails.assignType = assigneeType;
      this.showFullSection = true;
    },

    getSection(classId) {
     
      if (classId) {
        this.sectionList = [];
        this.sectionList = this.classList.find(
          (x) => x._id == classId
        ).sections;
        
        this.sectionList.forEach((y) => {
          y["isSelected"] = false;
        });
      } else {
        this.sectionList = [];
      }
    },
    getSectionAndSetValue(classId) {
      if (classId) {
        this.sectionList = [];
        this.sectionList = this.classList.find(
          (x) => x._id == classId
        ).sections;

        this.sectionList.forEach((y) => {
          if (y._id == this.classRoom) {
            y["isSelected"] = true;
          } else {
            y["isSelected"] = false;
          }
        });
      } else {
        this.sectionList = [];
      }
    },

    async getStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/contact/getStudentListForAssignment?classId=${this.classId}&sectionId=${this.classRoom}`,
          userData.token
        );
        if (response.isSuccess) {
          this.studentList = secureUI.secureGet(response.data);

          this.studentList.forEach((x) => {
            x["isSelected"] = false;
          });

          //    this.studentList.forEach((x) => {
          //   if (x.isSelected) {
          //     selectedStudentList.push(x._id);
          //   }
          // });
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    async getClassWithSection() {
      let userData = secureUI.sessionGet("user");

      let getClassCounts = this.$store.getters.getClassAndSectionList;

      if (getClassCounts.length == 0) {
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const response = await ViService.viXGet(
            `/academic/class_with_sections`,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);

            this.$store.dispatch("setClassAndSectionList", resultData.data);

            resultData.data.forEach((x) => {
              x["isSelected"] = false;
            });

            this.classList = resultData.data;
          } else {
            this.$toasted.error(response.message);
          }
        }
      } else {
        this.classList = this.$store.getters.getClassAndSectionList;

        this.classList.forEach((x) => {
          x["isSelected"] = false;
        });
      }
    },

    async getAllSectionList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/class/getAllSectionList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          var finalList = [];

          finalList = details.sections;

          finalList.forEach((x) => {
            x["isSelected"] = false;
          });

          this.allSectionList = finalList;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    async getAllTeacherClassAssignments() {
      let userData = secureUI.sessionGet("user");

      // let getClassCounts = this.$store.getters.getClassAndSectionList;

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/academic/teacher_class_assignment`,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);

          this.assignedStudentList = resultData;
        } else {
          this.$toasted.error(response.message);
        }
      }
    },

    addAssignment() {
     
      this.$bvModal.show("modalOne");
    },
    editAssignment(data) {
      
      this.asgntDetails = data;
      if (this.asgntDetails.assignment.publishedDate) {
        this.asgntDetails.assignment.publishedDate = this.asgntDetails.assignment.publishedDate.substring(0, 10);
      }

      if (this.asgntDetails.assignment.dueDate) {
        this.asgntDetails.assignment.dueDate = this.asgntDetails.assignment.dueDate.substring(0, 10);
      }
      this.assignmentTotalMarks = this.asgntDetails.assignment.totalMarks || 0
      this.$bvModal.show("modalOne");
    },

    openModalTwo(data) {
      if (data.studentAssignment.length > 0) {
        let filterClassRoom = data.studentAssignment.filter(
          (x) => x.classRoom.toString() == this.classRoom.toString()
        );

        this.evaluationList = filterClassRoom || [];
        this.$bvModal.show("modalTwo");
      }
    },

    closeModalTwo() {
      this.$bvModal.hide("modalTwo");
    },

    closeModalOne() {
      this.asgntDetails = {},
      this.$bvModal.hide("modalOne");
    },
  },
  components: {
    FullCalendar,
  },
};
</script>
